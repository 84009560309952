.footer-wrapper {
  width: 100%;
  background: #4a575d;
  padding: 10px 0px;
}

.footer-text {
  color: #fff;
  text-align: center;
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
@media only screen and (max-width: 480px) {
  .footer-text {
    font-size: 12px;
  }
}

.privacy-policy{
  cursor: pointer;
  color: #059fd9;
  font-weight: 500;
}
