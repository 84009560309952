@import url('https://db.onlinewebfonts.com/c/42396783e9149e0c23ebcc61fe85a153?family=Van+Condensed+W01+Regular');

.dashboard-hero-section {
  display: flex;
  align-items: center;
  width: 100%;
  background: url('../../../public/images/hero-section-bg.png');
  background-size: cover;
}

.hero-section-bg {
  /* background: rgba(70, 71, 72, 0.5); */
  display: flex;
  width: 100%;
  border-radius: 10px;
}

.hero-section-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  padding: 5% 0px 5% 5%;
}

.main-title {
  color: #ffb63b;
  font-family: Calibri;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  /* text-transform: capitalize; */
}

.main-title svg {
  margin-top: -10%;
}

.sub-title {
  color: white;
  /* font-family: "VanCondensedW01-Regular"; */
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.main-discription {
  color: #ffb63b;
  font-family: Calibri;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.sub-discription {
  color: white;
  /* font-family: Alice; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.hero-section-img img {
  height: 100%;
  width: 100%;
  padding: 2% 2% 0 2%;
}

@media only screen and (max-width: 1300px) {
  .main-title {
    font-size: 3rem;
  }
  .sub-title {
    font-size: 1.5rem;
  }
  .main-discription {
    font-size: 2rem;
  }
  .sub-discription {
    font-size: 1.3rem;
  }
  .main-title svg {
    margin-top: -15%;
    height: 10%;
    width: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .main-title {
    font-size: 3rem;
  }
  .sub-title {
    font-size: 1rem;
  }
  .main-discription {
    font-size: 2rem;
  }
  .sub-discription {
    font-size: 1rem;
  }
  .main-title svg {
    margin-top: -15%;
    height: 10%;
    width: 10%;
  }
}

@media only screen and (max-width: 480px) {
  .main-title {
    font-size: 1.5rem;
  }
  .sub-title {
    font-size: 0.7rem;
  }
  .main-discription {
    font-size: 1.2rem;
  }
  .sub-discription {
    font-size: 0.5rem;
  }
  .main-title svg {
    margin-top: -15%;
    height: 8%;
    width: 8%;
  }
  .hero-section-content {
    width: 70%;
  }
  .hero-section-img {
    width: 50%;
  }
}

/* ===============================================  card section ===================================== */

.container-fluid {
  padding: 0px !important;
}
.card-wrapper-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.four-card-warrper {
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: space-between;
}

.four-card-main {
  border-radius: 5px;
  background: #90959e;
  width: 25% !important;
  padding: 1% 2%;
}

.card-wrapper-elements {
  width: 20%;
  height: 20%;
}

.element-2 {
  margin-top: 8%;
}

.card-wrapper-elements img {
  height: 100%;
  width: 100%;
}

.card-wrapper-section {
  width: 100%;
  padding-top: 5%;
}

.card-row {
  width: 100%;
  display: contents;
}

.card-1 .card-text {
  color: #eeeeee;
  /* color: #059fd9; */
  font-family: Calibri;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.card-1 .card-title {
  /* color: #059fd9; */
  color: #eeeeee;
  font-family: Calibri;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

.card-2 .card-text {
  /* color: #e88e00; */
  color: #eeeeee;
  font-family: Calibri;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.card-2 .card-title {
  /* color: #e88e00; */
  color: #eeeeee;
  font-family: Calibri;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

.card-3 .card-text {
  color: #eeeeee;
  font-family: Calibri;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.card-3 .card-title {
  color: #eeeeee;
  font-family: Calibri;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

.card-4 .card-text {
  color: #eeeeee;
  /* color: #484eff; */
  font-family: Calibri;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
}
.card-4 .card-title {
  /* color: #484eff; */
  color: #eeeeee;
  font-family: Calibri;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

@media only screen and (max-width: 1300px) {
  .card-1 .card-title,
  .card-2 .card-title,
  .card-3 .card-title,
  .card-4 .card-title {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .card-1 .card-text,
  .card-2 .card-text,
  .card-3 .card-text,
  .card-4 .card-text {
    font-size: 0.8rem;
    font-weight: 300;
  }
}

@media only screen and (max-width: 768px) {
  .card-wrapper-elements {
    width: 20%;
  }
  .four-card-warrper {
    flex-direction: column;
    gap: 20px;
  }
  .four-card-main {
    width: 100% !important;
    padding: 15px 10px;
  }
  .card-row {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  .card-1 .card-text,
  .card-2 .card-text,
  .card-3 .card-text,
  .card-4 .card-text {
    font-size: 1rem;
    font-weight: 300;
  }
  .card-1 .card-title,
  .card-2 .card-title,
  .card-3 .card-title,
  .card-4 .card-title {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .element-2 {
    margin-top: 15%;
    left: 70%;
  }
  .card-wrapper-elements {
    width: 30%;
    height: 30%;
    position: absolute;
  }
  .card-wrapper-section {
    width: 90%;
    padding-top: 5%;
    padding-left: 10%;
  }
  .four-card-warrper {
    padding: 2%;
  }
}

@media only screen and (max-width: 480px) {
  /* .card-1 .card-title,.card-1 .card-text {
    color: #059fd9;
    border-radius: 5px;
  }
  .card-2 .card-title,.card-2 .card-text {
    color: #ff9c00;
    border-radius: 5px;
  }
  .card-3 .card-title,.card-3 .card-text {
    color: #90959e;
    border-radius: 5px;
  }
  .card-4 .card-title,.card-4 .card-text {
    color: #484eff;
    border-radius: 5px;
  } */
  .four-card-main {
    padding: 10px 10px;
  }
  .card-1 .card-text,
  .card-2 .card-text,
  .card-3 .card-text,
  .card-4 .card-text {
    font-size: 0.8rem;
    font-weight: 300;
    /* color: #eee; */
  }
  .card-1 .card-title,
  .card-2 .card-title,
  .card-3 .card-title,
  .card-4 .card-title {
    font-size: 1rem;
    font-weight: 700;
    /* color: #eee; */
  }
  .element-2 {
    margin-top: 25%;
    left: 75%;
  }
  .card-wrapper-elements {
    width: 25%;
    height: 25%;
    position: absolute;
  }
  .card-wrapper-section {
    width: 90%;
    padding-top: 10%;
    padding-left: 10%;
  }
  .four-card-warrper {
    padding: 2%;
  }
}

/* ========================================== testimonial-section ======================================== */
.testimonial-wrapper {
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 2px 40px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  padding-top: 5%;
  margin-top: -25%;
  margin-bottom: 2%;
}

.testimonial-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-card-title-1 {
  color: #059fd9;
  /* font-family: VanCondensedW01-Regular; */
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.testimonial-card-title-2 {
  color: #ff7d19;
  /* font-family: VanCondensedW01-Regular; */
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.testimonial-card-contant {
  padding-left: 20px;
}

.testimonial-card-list {
  color: #4a575d;
  /* font-family: VanCondensedW01-Regular; */
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
}

.testimonial-main {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 2% !important;
  padding: 5%;
  gap: 30px;
}

.testimonial-text {
  width: 20%;
  color: #212942;
  /* font-family: VanCondensedW01-Regular; */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.testimonial-card-btn {
  border: #059fd9 solid 1px;
  border-radius: 5px;
  color: #059fd9;
  width: 100%;
  background: white;
}

@media only screen and (max-width: 1300px) {
  .testimonial-wrapper {
    padding-top: 10%;
    margin-top: -20%;
    padding-bottom: 3%;
    margin-bottom: 2%;
  }
}
@media only screen and (max-width: 1200px) {
  .testimonial-wrapper {
    padding-top: 5%;
    margin-top: -25%;
    margin-bottom: 2%;
  }
  .testimonial-text {
    width: 20%;
    color: #212942;
    /* font-family: VanCondensedW01-Regular; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }
  .testimonial-card-title-1 {
    font-size: 1rem;
  }
  .testimonial-card-title-2 {
    font-size: 1rem;
  }
  .testimonial-card-list {
    font-size: 0.8rem;
  }
  .testimonial-img img {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 980px) {
  .testimonial-main {
    flex-direction: column;
    padding-top: 5% !important;
    gap: 20px;
  }
  .testimonial-text {
    width: 80%;
    color: #212942;
    text-align: center;
    /* font-family: VanCondensedW01-Regular; */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 768px) {
  .testimonial-wrapper {
    margin-top: -16%;
  }
  .testimonial-main {
    flex-direction: column;
    padding-top: 15% !important;
    gap: 20px;
  }
  .testimonial-text {
    width: 80%;
    color: #212942;
    text-align: center;
    /* font-family: VanCondensedW01-Regular; */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }
  .testimonial-img img {
    height: 90%;
    width: 90%;
  }
}
@media only screen and (max-width: 480px) {
  .testimonial-wrapper {
    margin-top: -20%;
  }
  .testimonial-main {
    padding-top: 20% !important;
    padding: 5%;
  }
  .testimonial-card {
    align-items: start;
  }
  .testimonial-text {
    width: 100%;
  }
  .testimonial-img {
    width: 30%;
    padding-top: 5px;
  }
  .testimonial-card-contant {
    width: 70%;
    padding-left: 10px;
  }
  .testimonial-img img {
    height: 100%;
    width: 100%;
  }
  .testimonial-card-title-1 {
    font-size: 1.2rem;
  }
  .testimonial-card-title-2 {
    font-size: 1.2rem;
  }
  .testimonial-card-list {
    font-size: 0.8rem;
  }
}
