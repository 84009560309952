.navbar-expand-lg {
  padding: 1% 0% !important;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.navbar-expand-lg .navbar-nav {
  gap: 30px;
}

.nav-item {
  color: #000;
  font-family: Calibri;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  text-transform: capitalize;
}

.nav-link:hover{
  cursor: pointer;
  color: #059FD9 !important;
}

.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #059FD9;
  transition: width .3s;
}

.cool-link:hover::after {
  width: 100%;
  /* //transition: width .3s; */
}

.active{
  color: #059FD9 !important;
  border-bottom: solid 2px #059FD9 !important;
}

@media only screen and (max-width: 1300px){
  .nav-item {
    font-size: 1.5rem;
  }
  .navbar-expand-lg .navbar-nav {
    gap: 10px;
  }
  .navbar-expand-lg {
    padding: 3% 0% !important;
  }
}

@media only screen and (max-width: 480px) {
  .navbar-brand img{
    height: 90%;
    width: 90%;
  }
  .nav-item {
    font-size: 1rem;
  }
  .navbar-expand-lg .navbar-nav {
    gap: 10px;
  }
  .navbar-expand-lg {
    padding: 3% 0% !important;
  }
}
