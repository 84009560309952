.about-hero-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: url('../../../public/images/about-us-bg.png');
  background-size: cover;
  padding: 5% 8%;
  gap: 20px;
  position: relative;
  z-index: 90;
}

.about-hero-section .title {
  font-family: Calibri;
  font-size: 4.5rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #ffb63b;
  line-height: 1;
}

.about-hero-section .discription {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 1000px) {
  .about-hero-section .title {
    font-size: 4rem;
  }

  .about-hero-section .discription {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .about-hero-section .title {
    font-size: 3rem;
  }

  .about-hero-section .discription {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .about-hero-section .title {
    font-size: 1.8rem;
  }

  .about-hero-section .discription {
    font-size: 0.7rem;
  }
}

/* ====================================== info section start ============================== */

.info-main {
  width: 100%;
  background: url('../../../public/images/itof-blue-circle-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -80px;
  z-index: 9;
}

.info-section .info-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 2.5rem;
  font-weight: 400;
  color: #059fd9;
  text-align: left;
  letter-spacing: 0em;
  text-transform: uppercase;
}
.info-section {
  padding-top: 100px;
}
.info-main .info-section .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-main .info-section .info .list-section {
  width: 60%;
}

.info-main .info-section .info .list-section p {
  padding: 10px 0;
}

.info-main .info-section .info .list-section p {
  color: #4a575d;
  font-size: 20px;
  text-transform: none;
}

.info-main .info-section .info .image-section {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}
.info-main .info-section .info .image-section img {
  height: 90%;
  width: 90%;
}

@media only screen and (max-width: 1000px) {
  .info-section .info-title {
    font-size: 2rem;
  }

  .info-main .info-section .info .list-section ul li {
    font-size: 16px;
  }
  .info-main .info-section .info .image-section {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .info-main {
    background-size: auto;
  }
  .info-main .info-section .info {
    flex-direction: column-reverse;
  }
  .info-section .info-title {
    font-size: 2rem;
  }
  .info-main .info-section .info .list-section ul li {
    font-size: 16px;
  }
  .info-main .info-section .info .list-section {
    width: 100%;
  }
  .info-main .info-section .info .image-section {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .info-main {
    background: none;
  }
  .info-main .info-section .info {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .info-section .info-title {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    background: url('../../../public/images/itof-blue-circle-bg.png');
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .info-main .info-section .info .list-section ul li {
    font-size: 16px;
  }
  .info-main .info-section .info .list-section {
    width: 100%;
  }
  .info-main .info-section .info .image-section {
    width: 100%;
  }
}

/* ===================================================== manegement section ============================== */

.management-main {
  width: 100%;
  background: url('../../../public/images/card-section-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.management-section .management-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #059fd9;
}

.card-section {
  padding: 2% 10%;
}

.card-row {
  width: 100%;
  margin-bottom: 10px;
}

.card-main {
  width: 100%;
  padding: 5% 2%;
  background: #ffffff !important;
  border-radius: 10px;
}

.card-main:hover {
  background: #bebebe !important;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-img {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.card-name {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.card-main:hover .card-body .card-name {
  color: #ffffff !important;
}

.management-card-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #059fd9 !important;
}

.card-main:hover .card-body .management-card-title {
  color: #ffffff !important;
}

.card-discription {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #4a575d;
  height: 230px;
}

.card-main:hover .card-body .card-discription {
  color: #ffffff !important;
}

@media only screen and (max-width: 768px) {
  .card-row {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .card-discription {
    height: 120px;
  }

  .card-img {
    display: flex;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  .management-section .management-title {
    font-size: 1.8rem;
    padding: 20px 20px;
  }
  .card-discription {
    height: 220px;
  }

  .card-img {
    display: flex;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
  }

  .row {
    margin: 0% !important;
  }
}

/* ======================================================= advisory section ============================= */

.advisory-main {
  width: 100%;
  padding: 2% 10%;
}

.advisory-section .advisory-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #059fd9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.advisory-card img {
  padding-bottom: 20px;
}

.advisory-card .card-main {
  background: url('../../../public/images/advisory-card-bg.png') !important;
  background-repeat: no-repeat !important;
}
.advisory-card .card-main:hover {
  padding: 0% !important;
  border: none;
}

.advisory-card .card-main:hover .advisory-img-hover-bg {
  width: 100%;
  background-size: cover !important;
  background: #e1e1e1 !important;
  padding: 2%;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.advisory-card .card-main:hover .advisory-text-hover-bg {
  width: 100%;
  background-size: cover !important;
  background: #bebebe !important;
  padding: 2%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.advisory-card .card-body {
  padding: 0% !important;
}

.advisory-card .card-main:hover .advisory-card-name {
  color: #ffffff;
  border: none;
}

.advisory-card-name {
  /* height: 80px; */
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.advisory-card-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #059fd9 !important;
  padding: 0px 10px;
}
.advisory-card .card-main:hover .advisory-card-title {
  color: #ffffff !important;
}

.advisory-card-discription {
  font-size: 14px;
  height: 300px;
  text-align: left;
  padding: 0px 10px;
}

.advisory-card .card-main:hover .advisory-card-discription {
  color: #ffffff;
}

@media only screen and (min-width: 1180px) {
  .advisory-card {
    width: 20% !important;
  }
}

@media only screen and (max-width: 480px) {
  .advisory-section .advisory-title {
    font-size: 2rem;
    text-align: center;
  }
  .row {
    margin: 0% !important;
  }
}

@media only screen and (max-width: 1300px) {
  .card-discription {
    height: 100%;
  }
  .advisory-card-discription {
    height: 100%;
  }
}
