.privacy-policy-hero-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: url("../../../public/images/Privacy-policy-bg.png");
  background-size: cover;
  padding: 8%;
  gap: 20px;
  position: relative;
  z-index: 90;
}

.privacy-policy-hero-section .title {
  font-family: Calibri;
  font-size: 4.5rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #ffb63b;
  line-height: 1;
}

.privacy-policy-hero-section .discription {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 1000px) {
  .privacy-policy-hero-section .title {
    font-size: 4rem;
  }

  .privacy-policy-hero-section .discription {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .privacy-policy-hero-section .title {
    font-size: 3rem;
  }

  .privacy-policy-hero-section .discription {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .privacy-policy-hero-section .title {
    font-size: 1.8rem;
  }

  .privacy-policy-hero-section .discription {
    font-size: 0.7rem;
  }
}


.privacy-policy-main {
  width: 100%;
}

.capital {
  text-transform: uppercase;
}

.privacy-policy-title {
  font-size: 3rem;
}

.policy-info-section {
  width: 100%;
  padding: 2% 2% !important;
  background: #f4f1f1;
  margin-bottom: 30px;
}

.policy-info-section .policy-info-title {
  font-size: 2.3rem;
  color: #059fd9;
  margin: 0px 0px 10px 0px;
}

.policy-note-text {
  color: #059fd9;
  font-weight: 500;
}

.policy-info-section .policy-info-text {
  font-size: 1.2rem;
  color: #4a575d;
}

.policy-info-points-section .points-row {
  display: flex;
  width: 100%;
  gap: 20px;
  margin: 20px 0px;
}

.policy-info-points-section .points-row .point-discription {
  font-size: 1.2rem;
  color: #4a575d;
}

.policy-orange-text {
  color: #ffb63b;
  font-weight: 500;
}

.policycontact-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.policy-contact-left {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.policy-contact-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border: solid 1px lightgrey;
  border-radius: 10px;
  background: #ffffff;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .policy-info-section{
    padding: 5% !important;
  }
  .policy-info-section .policy-info-title {
    font-size: 2rem;
    color: #059fd9;
    margin: 10px 0px;
  }
  .policy-info-section .policy-info-text {
    font-size: 1rem;
    color: #4a575d;
  }
  .policy-info-points-section .points-row .point-discription {
    font-size: 1rem;
    color: #4a575d;
  }
  .policycontact-section {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .privacy-policy-main {
    padding: 5%;
  }
  .policy-info-section {
    padding: 5% !important;
  }
  .policy-info-section .policy-info-title {
    font-size: 1.5rem;
    color: #059fd9;
    margin: 10px 0px;
  }
  .policy-info-section .policy-info-text {
    font-size: 1rem;
    color: #4a575d;
  }
  .policy-info-points-section .points-row .point-discription {
    font-size: 1rem;
    color: #4a575d;
  }
}
