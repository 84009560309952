.itof-hero-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: url("../../../public/images/itof-bg.svg");
  background-size: cover;
  padding: 2% 8% 4% 8%;
  gap: 20px;
  position: relative;
  z-index: 90;
}

.itof-hero-section .title {
  font-family: Calibri;
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #ffb63b;
  line-height: 1;
}

.itof-hero-section .discription {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 1000px) {
  .itof-hero-section .title {
    font-size: 2.5rem;
  }

  .itof-hero-section .discription {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .itof-hero-section .title {
    font-size: 2rem;
  }

  .itof-hero-section .discription {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .itof-hero-section .title {
    font-size: 1.2rem;
  }

  .itof-hero-section .discription {
    font-size: 0.7rem;
  }
}

/* ==================================== itof-cards-main =================================== */

.itof-cards-main {
  width: 100%;
  background: url("../../../public/images/itof-blue-circle-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -50px;
}

.itof-card-section {
  padding: 8% 10% 5% 10%;
}

.itof-card-row {
  width: 100%;
  margin-bottom: 10px;
}

.itof-card-main {
  background: url("../../../public/images/itof-card-blue-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right, bottom;
  height: 250px !important;
  border: solid 1px #484eff !important;
  border-radius: 10px;
}

.itof-card-discription {
  padding: 20px;
}

@media only screen and (min-width: 1180px) {
  .itof-card {
    width: 20% !important;
  }
}

@media only screen and (max-width: 1300px) {
  .itof-card-discription {
    overflow: hidden;
    max-height: 210px;
  }
}
@media only screen and (max-width: 768px) {
  .itof-card-section {
    padding-top: 12% !important;
  }
  .itof-card-main {
    height: 180px !important;
  }
}
@media only screen and (max-width: 480px) {
  .itof-card-section {
    padding-top: 20% !important;
  }
  .itof-card-main {
    height: 180px !important;
  }
}

/* ===================================== itof-info-section ==================================== */

.itof-info-main {
  background: #f6f6f7;
}

.itof-info-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3%;
  gap: 30px;
}

.itof-info-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #059fd9;
  text-transform: uppercase;
}

.itof-info-discription {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #4a575d;
  width: inherit;
}

.itof-info-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itof-btn {
  background: #059fd9;
  color: #ffffff;
  border-radius: 25px;
  padding: 10px 20px;
  border: none;
}

@media only screen and (max-width: 768px) {
  .itof-info-title {
    font-size: 30px;
  }
  .itof-info-discription {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .itof-info-title {
    font-size: 20px;
  }
  .itof-info-discription {
    font-size: 14px;
  }
}

/* ====================================== specs section ============================ */

.itof-specs-main {
  width: 100%;
  padding-bottom: 5%;
}

.itof-specs-section .itof-specs-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #059fd9;
  text-transform: uppercase;
  padding: 2% 0%;
}

.itof-specs-info-main {
  border: solid 1px#90959E;
  border-radius: 10px;
  background: url("../../../public/images/itof-specs-blue-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right, top;
  padding: 3%;
}

.itof-specs-info-section {
  padding: 0% 5%;
}

.itof-specs-info-section .itof-specs-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10px;
}
.itof-specs-info-section .title-1 {
  color: #ffb63b;
}
.itof-specs-info-section .title-2 {
  color: #059fd9;
}

.itof-specs-info-section .itof-specs-points-section ul li {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #4a575d;
}

@media only screen and (max-width: 768px) {
  .itof-specs-info-section .itof-specs-title {
    font-size: 30px;
  }
  .itof-specs-info-section .itof-specs-points-section ul li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .itof-specs-section .itof-specs-title {
    font-size: 20px !important;
  }

  .itof-specs-info-section .itof-specs-points-section ul li {
    font-size: 10px;
  }
  .itof-specs-info-section .title-1 {
    font-size: 15px;
  }
  .itof-specs-info-section .title-2 {
    font-size: 15px;
  }
}

/* ========================================= itof-form-main ================================= */

.itof-form-main {
  width: 100%;
  background: #f6f6f7;
  padding-bottom: 5%;
}

.itof-form-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../../public/images/itof-form-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.itof-form-section .itof-form-title {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #059fd9;
  text-transform: uppercase;
  padding: 2% 0%;
}

.itof-form-section .itof-form-que {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.itof-form-section .itof-form-discription {
  /* font-family: VanCondensedW01-Regular; */
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #4a575d;
}

.itof-form-section .form-section {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itof-form-section .form-section .form-main form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media only screen and (max-width: 768px) {
  .itof-form-section .itof-form-title {
    font-size: 2rem;
  }
  .itof-form-section .itof-form-discription {
    font-size: 18px;
  }
  .itof-form-section .itof-form-que {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .itof-form-section .itof-form-title {
    font-size: 1rem;
  }
  .itof-form-section .itof-form-discription {
    font-size: 14px;
  }
  .itof-form-section .itof-form-que {
    font-size: 14px;
  }
}
